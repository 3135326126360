import httpClient from "../functions/httpClient";
// import { getHeaders } from "./index";

// -------------------------------------- GLOBAL --------------------------------------
// -------------------------------------- CATEGORIES --------------------------------------
export const getCategories = () => {
  return httpClient.GET("/global/categories");
};
export const addCategory = (categoryForm) => {
  return httpClient.POST("/global/categories", null, null, categoryForm);
};
export const updateCategory = (categoryId, categoryForm) => {
  return httpClient.PUT(
    "/global/categories/" + categoryId,
    null,
    null,
    categoryForm
  );
};
export const deleteCategory = (categoryId) => {
  return httpClient.DELETE("/global/categories/" + categoryId);
};
// -------------------------------------- END CATEGORIES --------------------------------------

// -------------------------------------- PRODUCTS --------------------------------------
export const getProductsCount = (_token, params) => {
  return httpClient.GET("/global/products/count", null, params);
};
export const getProducts = (_token, params) => {
  return httpClient.GET("/global/products", null, params);
};
export const getProductById = (productId, _token, params) => {
  return httpClient.GET("/global/products/" + productId, null, params);
};
export const addProduct = (productForm) => {
  return httpClient.POST("/global/products", null, null, productForm);
};
export const updateProduct = (productId, productForm) => {
  return httpClient.PUT(
    "/global/products/" + productId,
    null,
    null,
    productForm
  );
};
export const deleteProduct = (productId) => {
  return httpClient.DELETE("/global/products/" + productId);
};
// -------------------------------------- PRODUCT IMAGES --------------------------------------
export const getProductImages = (productId) => {
  return httpClient.GET("/global/products/" + productId + "/images");
};
export const addProductImage = (productId, productImageData) => {
  return httpClient.POST(
    "/global/products/" + productId + "/images",
    null,
    null,
    productImageData
  );
};
export const deleteProductImage = (productId, productImageId) => {
  return httpClient.DELETE(
    "/global/products/" + productId + "/images/" + productImageId
  );
};
// -------------------------------------- END PRODUCT IMAGES --------------------------------------
// -------------------------------------- END PRODUCTS --------------------------------------

// -------------------------------------- END GLOBAL --------------------------------------
