<template>
  <b-card>
    <b-card-title class="text-primary">Global Product Images</b-card-title>

    <!-- <div class="scrolling-wrapper row flex-row flex-nowrap mb-4"> -->
    <GlobalProductImage
      v-for="(image, index) in combinedImages"
      :key="index"
      :src="image.src"
      :enableDelete="true"
      @deleteImage="() => deleteImageClicked(image)"
      class="mb-3"
    />
    <!-- </div> -->

    <!-- Button to add Image -->
    <b-button @click="onAddImageClicked" block pill variant="outline-primary">
      Add Product Image
    </b-button>

    <!-- Remove or Change Image Modal -->
    <b-modal v-model="showUploadImageModal" centered hide-header hide-footer>
      <!-- Image Uploader -->
      <image-uploader
        id="selectionImage"
        :debug="0"
        :maxWidth="500"
        :maxHeight="500"
        :quality="1"
        outputFormat="blob"
        :preview="false"
        accept="image/*"
        doNotResize="['gif', 'svg']"
        @input="onFileSelected"
      />
    </b-modal>
  </b-card>
</template>

<script>
import Jimp from "jimp";

import { mapGetters } from "vuex";
import GlobalProductImage from "./GlobalProductImage.vue";

export default {
  components: { GlobalProductImage },
  props: ["productId"],
  data() {
    return {
      showUploadImageModal: false,
      addedImages: [],
    };
  },
  computed: {
    ...mapGetters({
      adminToken: "admin/getToken",
      getProductImages: "admin/globalProductImages/getProductImages",
    }),
    productImages() {
      if (this.productId) {
        return this.getProductImages(this.productId);
      }
      return [];
    },
    combinedImages() {
      return this.productImages.concat(this.addedImages);
    },
  },
  methods: {
    onAddImageClicked() {
      this.showUploadImageModal = true;
    },
    onFileSelected(selectedFile) {
      // add image to addedImages
      this.compressFile(selectedFile).then((file) => {
        this.fileToURL(file).then((data) => {
          this.addedImages.push({ id: null, src: data, file });
          this.$emit("imageAdded", file);
        });
      });
      // close modal
      this.showUploadImageModal = false;
    },
    deleteImageClicked(image) {
      const index = this.addedImages.indexOf(image);
      if (index !== -1) {
        this.addedImages.splice(index, 1);
      }
      this.$emit("deleteImage", image);
    },

    async compressFile(file) {
      const buffer = await this.fileToBufferArray(file);
      const image = await Jimp.read(buffer);
      const jimpImage = image.quality(60);

      const array = await jimpImage.getBufferAsync(Jimp.MIME_JPEG);
      return new Blob([array], { type: Jimp.MIME_JPEG });
    },
  },
};
</script>

<style></style>
