<template>
	<b-card header-text-variant="primary" header-class="text-center" header-tag="header">
		<template #header>
			<b-card-title>
				{{ isNew ? `Create Global Product` : `Update Global Product` }}
			</b-card-title>
		</template>

		<!-- PRODUCT IMAGES -->
		<GlobalProductImages :productId="productId" @imageAdded="onImageAdded" @deleteImage="onDeleteImage" class="mb-3" />

		<!-- Title -->
		<b-card-title class="text-primary">
			{{ isNew ? `Create Global Product` : `Edit Global Product` }}
		</b-card-title>

		<!-- FORM -->
		<b-form>
			<!-- Product Name -->
			<b-form-group label="Product Name*" label-for="name" :invalid-feedback="getErrorMessage(errors, 'name')" :state="getErrorState(errors, 'name')">
				<b-form-input :state="getErrorState(errors, 'name')" type="text" id="name" v-model="form.name" placeholder="Product Name" trim />
			</b-form-group>

			<!-- Unit -->
			<b-form-group label="Select Unit*" label-for="unit" :invalid-feedback="getErrorMessage(errors, 'unit')" :state="getErrorState(errors, 'unit')">
				<b-form-select :state="getErrorState(errors, 'unit')" id="unit" v-model="form.unit" :options="unitOptions" />
			</b-form-group>

			<!-- Categories -->
			<b-form-group label="Choose Category" label-for="categoryId" :invalid-feedback="getErrorMessage(errors, 'categoryId')" :state="getErrorState(errors, 'categoryId')">
				<b-input-group>
					<!-- Dropdown to select category -->
					<b-form-select :state="getErrorState(errors, 'categoryId')" id="categoryId" v-model="form.categoryId" :options="categories" value-field="id" text-field="name">
						<!-- This slot appears above the options from 'options' prop -->
						<template #first>
							<b-form-select-option :value="null">
								-- No Category --
							</b-form-select-option>
						</template>
					</b-form-select>

					<!-- Create Category Option -->
					<b-input-group-append v-if="!form.categoryId">
						<b-button @click.prevent="onCreateCategoryClicked" variant="outline-secondary">
							Create Category
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<!-- Buttons -->
			<div class="text-center">
				<b-button class="mr-2" variant="primary" @click="onSubmit">
					{{ isNew ? "Create" : "Update" }}
				</b-button>
				<b-button @click="onCancel"> Cancel </b-button>
			</div>
		</b-form>

		<!-- Modal to add Category -->
		<b-modal v-model="showCreateCategoryModal" centered hide-header hide-footer>
			<GlobalCategoryForm @success="onCategoryCreationSuccess" @cancel="onCategoryCreationCancel" />
		</b-modal>

		<!-- Image Error Msg Modal -->
		<b-modal v-model="showImageErrorMsgModal" centered hide-header hide-footer>
			<b-alert variant="danger" :show="!getErrorState(errors, 'image')">
				{{ getErrorMessage(errors, "image") }}
			</b-alert>
		</b-modal>
	</b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getCategories, getProductById, addProduct, updateProduct, getProductImages, addProductImage, deleteProductImage } from "../../../../apis/admin";

import GlobalCategoryForm from "../../../../components/categories/GlobalCategoryForm.vue";
import GlobalProductImages from "../../../../components/products/admin/global/GlobalProductImages.vue";

export default {
	components: { GlobalCategoryForm, GlobalProductImages },
	created() {
		this.initForm();
	},
	data() {
		return {
			// value from url
			productId: this.$route.query.productId,

			// product form
			form: {
				name: null,
				categoryId: null,
				unit: null,
			},
			errors: null,
			imageFiles: [],

			showCreateCategoryModal: false,
			showImageErrorMsgModal: false,
		};
	},
	computed: {
		...mapGetters({
			adminToken: "admin/getToken",
			categories: "admin/globalCategories/getList",
			unitOptions: "masters/getUnits",
		}),
		isNew() {
			if (this.productId) {
				return false;
			}
			return true;
		},
	},
	methods: {
		...mapActions({
			setCategories: "admin/globalCategories/setList",
			addProductToState: "admin/globalProducts/addItem",
			setProductImages: "admin/globalProductImages/setProductImages",
			addProductImageToState: "admin/globalProductImages/addProductImage",
			removeProductImageFromState: "admin/globalProductImages/removeProductImage",
		}),
		// Init
		initForm() {
			// setting categories
			getCategories().then((data) => {
				this.setCategories(data);
			});

			// if product id exist, fetch product and fill form
			if (this.productId) {
				getProductById(this.productId, this.adminToken, null)
					.then((product) => {
						// fill form with data from product
						this.form = {
							...product,
							categoryId: product.category ? product.category.id : null,
						};
						delete this.form.category;

						// fetch product images
						getProductImages(this.productId).then((data) => {
							// array of productImages setting to state
							const images = data.map((image) => {
								const src = image.productImage.blobUrl;
								return { id: image.id, src };
							});
							this.setProductImages({ id: this.productId, images });
						});
					})
					.catch(() => {
						this.$router.replace({ name: "GlobalProducts" });
					});
			}
		},

		onImageAdded(file) {
			this.imageFiles.push(file);
		},
		onDeleteImage(image) {
			if (image) {
				const imageId = image.id;
				if (imageId && this.productId) {
					const productId = this.productId;
					// deleting product image from server
					deleteProductImage(productId, imageId, this.adminToken)
						.then(() => {
							this.onImageDeleteSuccess(productId, imageId);
						})
						.catch(({ data }) => {
							this.errors = data.errors;
							this.showImageErrorMsgModal = true;
						});
				} else if (!imageId) {
					// delete image from imageFiles
					const index = this.imageFiles.indexOf(image.file);
					if (index !== -1) {
						this.imageFiles.splice(index, 1);
					}
				}
			}
		},
		// Submit
		onSubmit() {
			this.errors = null;
			const imagesToBeUploaded = this.imageFiles;

			if (this.isNew) {
				addProduct(this.form, this.adminToken)
					.then((data) => {
						// removing reference first
						this.imageFiles = [];
						this.onSuccess(data, imagesToBeUploaded);
					})
					.catch(({ data }) => {
						this.onErrors(data);
					});
			} else {
				updateProduct(this.productId, this.form, this.adminToken)
					.then((data) => {
						// removing reference first
						this.imageFiles = [];
						this.onSuccess(data, imagesToBeUploaded);
					})
					.catch((err) => {
						this.onErrors(err.data);
					});
			}
		},
		onSuccess(data, imagesToBeUploaded) {
			if (this.isNew) {
				this.productId = data.id;
			}

			// add product to state
			this.addProductToState(data);

			// if images were upload, add images to product
			this.uploadImages(imagesToBeUploaded);

			this.$router.replace({ name: "GlobalProducts" });
		},
		uploadImages(imagesToBeUploaded) {
			if (imagesToBeUploaded) {
				imagesToBeUploaded.forEach((file) => {
					const productImageData = new FormData();
					productImageData.append("globalProductImage", file, file.name);
					addProductImage(this.productId, productImageData, this.adminToken).then(this.onImageUploadSuccess);
				});
			}
		},
		onImageUploadSuccess(data) {
			// adding product image to state
			const src = data.productImage.blobUrl;
			this.addProductImageToState({
				id: this.productId,
				image: { id: data.id, src },
			});
		},
		onImageDeleteSuccess(productId, imageId) {
			this.removeProductImageFromState({ id: productId, imageId });
		},
		onErrors(data) {
			this.errors = data.errors;
		},
		onCancel() {
			this.$router.go(-1);
		},

		// Category
		onCreateCategoryClicked() {
			this.showCreateCategoryModal = true;
		},
		onCategoryCreationSuccess(data) {
			this.showCreateCategoryModal = false;
			this.form.categoryId = data.id;
		},
		onCategoryCreationCancel() {
			this.showCreateCategoryModal = false;
		},
	},
};
</script>

<style></style>
