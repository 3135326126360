<template>
  <span class="img-wraps">
    <b-icon-x-circle-fill
      v-if="enableDelete"
      @click="deleteImageClicked"
      class="closes"
    />
    <b-img v-bind="imgProps" :src="src" class="m-2" />
  </span>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgProps: { rounded: true, width: 100, height: 100 },
    };
  },
  methods: {
    deleteImageClicked() {
      this.$emit("deleteImage");
    },
  },
};
</script>

<style scoped>
/* For delete overlay */
.img-wraps {
  position: relative;
  display: inline-block;
}
.img-wraps .closes {
  position: absolute;
  /* top: 5px; */
  right: 0px;
  bottom: 0px;
  z-index: 100;
  background-color: #fff;

  color: red;
  cursor: pointer;

  font-size: 20px;
  border-radius: 100%;
  /* border: 1px solid red; */
}
.img-wraps:hover .closes {
  opacity: 1;
}
</style>
